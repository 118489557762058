.section-main-div {
  padding: 6rem 15px;
}

.section-title-bg-absolute {
  position: absolute;
  margin: 0;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  text-transform: uppercase;
  font-weight: 900;
  font-size: calc(2.5rem + 8vw);
  -webkit-text-stroke: 1px var(--text-stroke);
  -webkit-text-fill-color: transparent;
  z-index: 8;
}

.section-text-div {
  position: relative;
  height: calc(3.5rem + 10vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-title {
  text-align: center;
  text-transform: uppercase;
  color: var(--primary);
  font-weight: 700;
  font-size: calc(1.375rem + 1.5vw);
  z-index: 9;
}

@media (max-width: 576px) {
  .section-main-div {
    padding: 6rem 2rem 1rem !important;
  }
}

@media (min-width: 992px) {
  .section-main-div {
    padding: 6rem 0 1rem !important;
  }
}

@media (min-width: 1200px) {
  .section-main-div {
    padding: 6rem 3rem 1rem !important;
  }
}

@media (min-width: 1400px) {
  .section-main-div {
    padding: 6rem 7rem 1rem !important;
  }
}
