.certification-item-provider-img {
  height: 5.5rem;
}

.certification-item-title {
  color: var(--dark);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
}

.certification-item-provider-name {
  font-size: 1.1rem;
  color: var(--secondary);
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.certification-item-expiry {
  font-size: 0.92rem;
  color: var(--secondary);
  margin-bottom: 0.3rem;
}
