.navbar-navlink {
  text-decoration: none !important;
  color: var(--dark) !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  cursor: pointer !important;
  transition: 0.1s color !important;
  margin: 0 0.6rem !important;
}

.navbar-navlink:hover {
  color: var(--primary) !important;
}
