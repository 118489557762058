.app-custom-alert {
  position: fixed !important;
  width: 50vw;
  top: 1%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 30;
  text-align: center;
  display: none;
}

.show {
  display: block !important;
}

@media (max-width: 992px) {
  .app-custom-alert {
    width: 90vw;
  }
}
