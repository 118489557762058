.aboutme-details-main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.aboutme-details-h2 {
  font-weight: 700;
  color: var(--dark);
  font-size: 1.9rem;
  margin-bottom: 1.5rem;
}

.aboutme-details-summary {
  color: var(--secondary);
  font-size: 1rem;
  margin-bottom: 2.5rem;
}
